.App {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;

  position: relative;
  text-align: center;
  overflow: hidden;
}

.App-header {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.menu-bar {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 8px 24px;
  justify-content: space-between;
  box-sizing: border-box;
}
.logo {
  width: auto;
  height: 60px;
}

.social-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.social-label {
  color: #131313;
  font-family: futura-pt, sans-serif;
  font-size: 16px;
  font-weight: 500;
}

.social-icons {
  display: flex;
  align-items: center;
}

.social-icon {
  width: 50px;
  height: auto;
  cursor: pointer;
}

.heading {
  color: #00ab63;
  font-size: 68px;
  margin: 0;
  line-height: 68px;
  text-transform: uppercase;
  font-family: "Bowlby One", cursive;
}

.pretty-cactus {
  width: 300px;
  position: relative;
  bottom: -5%;
  left: 50%;
  transform: translate(-50%, 0);
}

.small {
  width: 400px;
  height: 400px;
}

.medium {
  width: 600px;
  height: 600px;
}

.large {
  width: 600px;
  height: 600px;
}

.left-bottom {
  bottom: 0%;
  left: 0%;
}

.left-top {
  top: 5%;
  left: 0%;
}

.right-bottom {
  bottom: 15%;
  right: 15%;
}

.right-top {
  top: 10%;
  right: 15%;
}

.blurry-gradient {
  position: fixed;

  border-radius: 50% 66% 40% 80%;
  filter: blur(100px);
  background: radial-gradient(
    circle at 50% 50%,
    rgb(129, 135, 255),
    rgba(76, 0, 255, 0)
  );
  opacity: 0.2;
}
